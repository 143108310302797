import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dashboard, MainTable } from "components";
import { getYearlyRequestAbsences } from "actions/absences";

const RequestAbsences = ({
  getYearlyRequestAbsences,
  filterUnAcceptedAbsences,
  request_absences,
}) => {
  useEffect(() => {
    getYearlyRequestAbsences();
  }, []);

  const { status, data } = request_absences;
  const absences_data = data?.sort((a, b) => b.days_count - a.days_count);
  return (
    <Dashboard
      className="m-b-10"
      header={{ title: `Urlopy na żądanie ${new Date().getFullYear()}` }}
    >
      <MainTable
        is_loading={status === "invalid" || status === "loading"}
        data={absences_data}
        head={[
          {
            label: "Imię i nazwisko",
            name: "user",
            sortable: false,
            style: { width: "200px" },
          },
          {
            label: "Ilość dni",
            name: "days_count",
            style: { width: "150px" },
            sortable: false,
          },
        ]}
        empty_text="Brak urlopów na żądanie"
        filtersAction={filterUnAcceptedAbsences}
        renderRow={({ id, full_name, days_count }) => (
          <tr key={id} style={{ cursor: "default" }}>
            <td>{full_name}</td>
            <td>{days_count}</td>
          </tr>
        )}
      />
    </Dashboard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getYearlyRequestAbsences: () => dispatch(getYearlyRequestAbsences()),
});
export default connect(
  ({ request_absences }) => ({
    request_absences,
  }),
  mapDispatchToProps
)(withRouter(RequestAbsences));
