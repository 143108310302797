import React from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { Main, Button } from "components";
import { ReactComponent as HolidayIcon } from "icons/holiday.svg";

import getDepartmentsOptions from "utils/select_options/getDepartmentsOptions";

import Accepted from "./components/Accepted";
import UnAccepted from "./components/UnAccepted";
import RequestAbsences from "./components/RequestAbsences";

const AbsencesList = ({ current_user }) => {
  const { isFetching: is_fetching_departments, data: departments } = useQuery(
    "departments",
    getDepartmentsOptions,
    {
      enabled: current_user?.is_admin,
    }
  );
  return (
    <Main
      page={{
        name: "Nieobecności",
        icon: <HolidayIcon />,
        breadcrumbs: [{ name: "Nieobecności" }],
        buttons: (
          <Button type="add" text="Dodaj nieobecność" path="/absences/new" />
        ),
      }}
    >
      <div className="dashboard-wrapper-flex">
        <div className={current_user?.is_admin ? "column-2" : "column-full"}>
          <UnAccepted
            is_fetching_departments={is_fetching_departments}
            departments={departments}
          />
        </div>
        {current_user?.is_admin && (
          <div className="column-1">
            <RequestAbsences />
          </div>
        )}
      </div>
      <Accepted
        is_fetching_departments={is_fetching_departments}
        departments={departments}
      />
    </Main>
  );
};
export default connect(({ current_user }) => ({
  current_user,
}))(AbsencesList);
